<template>
  <router-view />
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Admin',
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    this.$store.commit('common/INCREASE_COUNTER')
    let unwatch = null
    unwatch = this.$watch(
      'user',
      (user) => {
        global.debug(user)
        if (user) {
          this.$store.commit('common/DECREASE_COUNTER')
          if (!user.isAdmin) {
            this.$router.push({ name: 'dashboard' })
          }
          setTimeout(unwatch, 200)
        }
      },
      { immediate: true }
    )
  },
}
</script>
